// Import our CSS
import styles from '../css/app.pcss';

// Import non-Vue JS libraries
import 'promise-polyfill/src/polyfill';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Swiper from 'swiper';
import VModal from 'vue-js-modal';

import CO2Calculator from '../vue/CO2Calculator.vue';


// App main
const main = async () => {
    // Async load modules
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const vClickOutside = await import( /* webpackChunkName: "v-click-outside" */ 'v-click-outside');
    const axios = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueAxios = await import( /* webpackChunkName: "vue-axios" */ 'vue-axios');
    
    Vue.use(VueCookies);
    Vue.use(VModal);
    Vue.use(VueAxios, axios);
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        components: {
            'co2-calculator': CO2Calculator
        },
    
        directives: {
            clickOutside: vClickOutside.directive
        },
        
        data: {
            showCookieConsent: false,
            languageSwitcherOpen: false,
            mobileNavOpen: false,
            hamburgerInverted: false,
            videoModalEmbedCode: '',
            videoModalAspectRatio: '',
            vimeoUrl: '',
            isBreakpointSmallOnly: false,
            
            // Articles
            page: 1,
            elementsToLoad: 6,
            articlesLoadedCount: 0,
            totalCount: 0,
            loadingEntries: false,
            allLoaded: false
        },
        
        methods: {
            confirmCookieConsent() {
                let vm = this;
    
                VueCookies.set('HoutGeeftZuurstofCookieConsent', 'confirmed', {expires: 365});
                setTimeout(() => {
                    vm.showCookieConsent = false;
                }, 200);
            },
    
            toggleLanguageSwitcher() {
                this.languageSwitcherOpen = !this.languageSwitcherOpen;
            },
    
            closeLanguageSwitcher() {
                this.languageSwitcherOpen = false;
            },
    
            openMobileNav() {
                this.mobileNavOpen = !this.mobileNavOpen;
                
                if(this.mobileNavOpen){
                    disableBodyScroll(this.$refs.navMain);
                } else {
                    enableBodyScroll(this.$refs.navMain);
                }
            },
    
            closeModal(name) {
                this.$modal.hide(name);
            },
    
            showModalVideo(embedCode, aspectRatio){
                this.$modal.show('modal-video', { embedCode: embedCode });
                this.videoModalAspectRatio = aspectRatio;
            },
    
            beforeModalVideoOpen(event){
                this.videoModalEmbedCode = event.params.embedCode;
            },
    
            getVideoThumb(videoSource, embedCode){
                let vm = this;
                let url = '';
                
                if (videoSource === 'youtube'){
                    
                    // Get the URL from the embed code
                    let videoUrl = embedCode.match(/\bhttps?:\/\/\S+/gi).toString();
    
                    // Strip optional trailing slash from URL
                    videoUrl = videoUrl.endsWith('/') ? videoUrl.slice(0, -1) : videoUrl;
                    
                    // Get the YouTube video ID (last segment) from the URL and remove any queryStrings
                    let youtubeId = videoUrl.toString().replace('"', '').split("/").pop().split("?")[0];
                    
                    // Compose the YouTube thumbnail URL
                    url = 'https://img.youtube.com/vi/' + youtubeId + '/maxresdefault.jpg';
                    
                } else if (videoSource === 'vimeo'){
                    
                    // Get the first URL from the embed code
                    let videoUrl = embedCode.match(/\bhttps?:\/\/\S+/gi)[0].toString();
    
                    // Strip optional trailing slash from URL
                    videoUrl = videoUrl.endsWith('/') ? videoUrl.slice(0, -1) : videoUrl;
                    videoUrl = videoUrl.replace('"', '');
                    
                    // Get JSON data from the Vimeo URL
                    axios.get("https://vimeo.com/api/oembed.json?url=" + videoUrl)
                        .then(response => {
                            vm.vimeoUrl = response.data.thumbnail_url;
                        })
                        .catch(error => {
                            console.log("error fetching Vimeo JSON", error);
                        });
                    
                    url = this.vimeoUrl
                }
                
                return url;
            },
    
            loadMoreArticles() {
                let vm = this;
        
                // Load more btn was clicked so we're loading entries and we're hiding the button and showing the loader
                this.loadingEntries = true;
        
                axios.get("ajax/articles/p" + this.page)
                    .then(function (response) {
                        // Get element to append articles to
                        let parent = document.getElementById('articles-wrapper');
                        parent.insertAdjacentHTML('beforeend', response.data);
                        
                        // Set next pagination page
                        vm.page++;
                
                        // Update our article loaded count
                        vm.articlesLoadedCount += vm.elementsToLoad;
                
                        // Show the load more articles button again
                        vm.loadingEntries = false;
                
                        // Fade in new elements (set timeout because else it doesn't work properly)
                        setTimeout(function () {
                            for (const el of Array.from(document.getElementsByClassName('newly-loaded'))) {
                                el.classList.remove("opacity-0");
                            }
                        }, 100);
                
                
                        // Check if all articles are loaded to hide the load more button
                        if (vm.articlesLoadedCount >= vm.totalCount) {
                            vm.allLoaded = true;
                        }
                    })
                    .catch(function (error) {
                        console.log("error fetching articles", error);
                    });
            },
            
            onWindowScroll() {
                // 48px = height of hamburger + half of margin-top
                this.hamburgerInverted = this.$refs.siteHeader.offsetHeight < (window.pageYOffset + 48);
            }
        },
        
        computed: {
            getVideoModalAspectRatio(){
                return 'content-block--embed-' + this.videoModalAspectRatio;
            }
        },
    
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
        },
    
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
        },
        
        mounted() {
            let vm = this;
            this.onWindowScroll();
            
            
            /* Load more articles */
            if (this.$refs.articles) {
                this.totalCount = this.$refs.articles.dataset.count;
            }
            this.articlesLoadedCount = this.elementsToLoad;
    
            // Using deprecated addListener() instead of addEventListener('change')
            // because Safari does not support addEventListener on MediaQueryList
            // cfr.: https://github.com/mdn/sprints/issues/858
            /* Set JS small-only mediaQuery */
            let breakpointSmallOnly = window.matchMedia( '(max-width: 767px)' );
            // Listen when this changes
            breakpointSmallOnly.addListener(() => {
                this.isBreakpointSmallOnly = breakpointSmallOnly.matches;
            });
            // Set initial value
            this.isBreakpointSmallOnly = breakpointSmallOnly.matches;
            
            
            /* Show cookie consent with delay */
            setTimeout(() => {
                vm.showCookieConsent = true;
            }, 600);
        },
    });

    return vm;
};

// Execute async function
main().then( (vm) => {
    /* Swiper on mobile only */
    
    // breakpoint where swiper will be destroyed
    const breakpoint = window.matchMedia( '(min-width: 576px)' );
    
    // keep track of swiper instances to destroy later
    let featuredPagesSwiper;
    
    const breakpointChecker = function() {
        // if larger viewport
        if ( breakpoint.matches === true ) {
            // clean up old instances and inline styles when available
            if ( featuredPagesSwiper !== undefined ) featuredPagesSwiper.destroy( true, true );
            // or/and do nothing
            return;
            // else if a small viewport
        } else if ( breakpoint.matches === false ) {
            return enableSwiper();
        }
    };
    
    const enableSwiper = function() {
        featuredPagesSwiper = new Swiper ('.featured-pages', {
            spaceBetween: 5,
            centeredSlides: true,
            a11y: true,
            loop: true,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            observer: true
        });
    };
    
    // keep an eye on viewport size changes
    breakpoint.addListener(() => {
        breakpointChecker();
    });
    
    breakpointChecker();
    
    
    /* Image component swiper */
    
    let imagesComponentSwiper = new Swiper ('.swiper-container-general', {
        centeredSlides: true,
        a11y: true,
        watchOverflow: true,
        //loop: true,
        autoplay: {
            delay: 10000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        },
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev'
        }
    })
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
