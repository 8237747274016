<template>
    <div class="calculator-wrapper row-ic" ref="calculatorprint">
        <div class="icon-calc-large no-print">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 48"><defs><path id="a" d="M0 48h36V0H0z"/></defs><g fill="none" fill-rule="evenodd"><path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M33 47H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2v42a2 2 0 0 1-2 2z"/><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path fill="#FFF" d="M7 15h22V7H7z" mask="url(#b)"/><path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 21v8M15 25H7M23 39h6M27 25L9 39" mask="url(#b)"/></g></svg>
        </div>
        
        <div class="flex justify-between items-end w-full mb-16 only-print">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271 142"  fill="#6bab3e" width="200">
                <g fill-rule="evenodd">
                    <path d="M270.883 109.884c0-6.774-1.638-13.467-5.573-19.002-6.884-9.583-15.818-11.401-26.8-11.401h-20.08v61.138h21.228c19.833 0 31.225-10.657 31.225-30.735m-15.49.25c0 9.914-4.262 16.771-14.834 16.771h-6.639v-33.71h6.72c10.328 0 14.753 7.189 14.753 16.939M211.255 110.05c0-17.894-14.342-31.52-32.045-31.52-10.327 0-19.424 4.269-25.652 12.478-1.558 2.052-3.033 4.43-3.934 6.811-1.64 4.187-2.377 8.455-2.377 12.97 0 17.484 14.752 30.781 31.881 30.781 17.785 0 32.127-13.544 32.127-31.52m-15.49.083c0 9.275-6.556 17.811-16.308 17.811-10.082 0-16.72-8.371-16.72-18.057 0-9.524 6.638-17.731 16.555-17.731s16.473 8.536 16.473 17.977M62.041 79.481l-8.278 39.906H53.6l-8.77-39.906H32.947l-8.934 39.741h-.164l-8.114-39.741H0l15.653 61.139h14.016l9.097-39.575h.163l9.098 39.575h13.604l16.065-61.139zM146.112 65.541c0-10.613-8.589-19.215-19.185-19.215-10.596 0-19.186 8.602-19.186 19.215 0 1.86.277 3.65.77 5.35a36.57 36.57 0 0 1 2.677-.109c10.11 0 19.116 4.448 24.99 11.591 5.92-3.271 9.934-9.58 9.934-16.832m-37.601 5.35c-9.216.68-17.292 4.877-22.975 12.367-1.557 2.053-3.032 4.433-3.934 6.814-1.64 4.185-2.377 8.454-2.377 12.969 0 17.483 14.753 30.781 31.88 30.781 17.786 0 32.128-13.544 32.128-31.52 0-7.676-2.644-14.561-7.056-19.929a19.069 19.069 0 0 1-9.25 2.383c-8.74 0-16.101-5.858-18.416-13.865m2.76 13.516c9.916 0 16.473 8.537 16.473 17.978 0 9.275-6.557 17.81-16.31 17.81-10.08 0-16.72-8.371-16.72-18.056 0-9.522 6.64-17.732 16.557-17.732M100.764 51.532c7.225 0 13.081-5.866 13.081-13.102 0-7.235-5.856-13.1-13.08-13.1-7.225 0-13.082 5.865-13.082 13.1 0 7.236 5.857 13.102 13.081 13.102M117.624 19.216c5.298 0 9.594-4.303 9.594-9.609S122.922 0 117.624 0c-5.297 0-9.592 4.3-9.592 9.607 0 5.306 4.295 9.609 9.592 9.609"/>
                    <path d="M137.913 140.62v-2.94h-4.632c2.049-1.793 4.54-4.067 4.54-7.024 0-.537-.129-1.073-.35-1.572-.794-1.664-2.53-2.405-4.301-2.405-1.661 0-3.286.668-4.21 2.09-.645.999-.775 1.978-.811 3.144h3.322c.056-1.017.314-2.163 1.57-2.163.793 0 1.292.554 1.292 1.33 0 1.22-1.09 2.626-1.883 3.495-1.237 1.368-2.64 2.33-4.171 3.327v2.718h9.634z"/>
                </g>
            </svg>
            <h1>{{ translations.certificaat }}</h1>
        </div>
        
        <!--<transition name="fade" mode="out-in">-->
            <div v-if="!showResults" key="calculatorContent" class="row-ic calculator-content no-print">
                <h2 class="mb-8">{{ translations.titleCalculator }} m<sup>3</sup></h2>
                
                <transition-group name="fade" tag="div">
                    <div class="calculator__input-row mb-6" v-for="(row, index) in calculatorData" :key="'row' + index">
                        <div class="select-styling mr-8" :class="{ invalid: errors.first('woodType' + index) }">
                            <select :name="'woodType' + index"
                                    v-model="calculatorData[index].wood"
                                    @change="firstWoodTypeNotSelected = false"
                                    v-validate="'required'">
                                <option v-for="(type, i) in woodTypes" :value="type">{{ type.woodType }}</option>
                            </select>
                        </div>
                        
                        <div class="field-sticky-label mr-8">
                            <input type="number"
                                   name="woodVolume"
                                   :placeholder="translations.volume"
                                   v-model="row.volume"
                                   min="0.1"
                                   step="0.1"
                                   v-validate="'required'" />
                            <div class="sticky-label">m³</div>
                        </div>
                        
                        <div class="calculator__input-row__buttons">
                            <button class="btn--delete mr-8" v-if="calculatorData.length !== 1" @click="deleteRow(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25" fill="none" stroke="#6BAB3E">
                                    <g fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                        <path d="M1 4h19M14.5 4h-8v-.69A2.31 2.31 0 0 1 8.81 1h3.38a2.31 2.31 0 0 1 2.31 2.31V4zM10.5 8v12M6.5 8v12M14.5 8v12M14.646 24H6.354a3 3 0 0 1-2.995-2.851L2.5 4h16l-.858 17.149A3 3 0 0 1 14.647 24z" />
                                    </g>
                                </svg>
                            </button>
                            
                            <button class="btn btn--add-row" v-if="index === calculatorData.length - 1" @click="addNewRow()">
                                <svg class="btn__icon icon-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.4 31.4" fill="#fff">
                                    <path d="M15.7 31.4c-1.1 0-2-.9-2-2V17.7H2c-1.1 0-2-.9-2-2s.9-2 2-2h11.7V2c0-1.1.9-2 2-2s2 .9 2 2v11.7h11.7c1.1 0 2 .9 2 2s-.9 2-2 2H17.7v11.7c0 1.1-.9 2-2 2z" />
                                </svg>
                                {{ translations.houtsoort }}
                            </button>
                        </div>
                    </div>
                </transition-group>
                
                <label class="mt-8 mb-6">{{ translations.lblRefProject }}</label>
                <input class="full-width" type="text" name="refProject" :placeholder="translations.placeRefProject" v-model="projectRef" />
                
                <div class="function-bar mt-12">
                    <button :disabled="firstWoodTypeNotSelected || calculatorData[0].volume === '' || calculatorData[0].volume === null || errors.all().length > 0" class="btn btn--filled self-start" @click="calculateResult()">{{ translations.btnCalculate }}</button>
                    <transition name="fade">
                        <span class="error" v-show="errors.all().length > 0">{{ translations.formGeneralError }}</span>
                    </transition>
                </div>
            </div>
        
            <div v-else key="calculatorResult" v-cloak class="row-ic calculator-result">
                <h2 class="mb-8"><span v-html="translations.titleCO2"></span> {{ totalCO2.toLocaleString() }}kg</h2>
                
                <p class="mb-3" v-if="this.projectRef">{{ translations['uw referentie'] }}: <span class="font-medium">{{ this.projectRef }}</span></p>
                <div class="calculator-result__summary">
                    <div class="calculator-result__row">
                        <div class="calculator-result__row__icon-wrapper">
                            <svg class="icon--volume" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 36" fill="none" stroke="#6BAB3E">
                                <g fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                    <path d="M32.385 22a1.44 1.44 0 0 1 0 2.36L17.892 34.717a1.544 1.544 0 0 1-1.784 0L1.615 24.36a1.44 1.44 0 0 1 0-2.36"/>
                                    <path d="M1.615 17a1.44 1.44 0 0 0 0 2.36l1.422 1.018 13.071 9.339c.53.377 1.254.377 1.784 0l13.07-9.339 1.423-1.018a1.44 1.44 0 0 0 0-2.36"/>
                                    <path d="M32.386 11.802L17.892 1.289a1.52 1.52 0 0 0-1.784 0L1.614 11.802a1.476 1.476 0 0 0 0 2.396l1.424 1.033 3.071 2.231 10 7.25a1.526 1.526 0 0 0 1.783 0l10-7.25 3.072-2.23 1.422-1.034a1.476 1.476 0 0 0 0-2.396z"/>
                                </g>
                            </svg>
                        </div>
            
                        <div class="calculator-result__row__label"><span v-html="translations.lblVolume"></span>:</div>
                        <div class="calculator-result__row__value">{{ totalVolume.toLocaleString() }} m<sup>3</sup></div>
            
                        <svg v-tooltip.left="translations.tooltipVolume"
                             class="calculator-result__info no-print"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
                            <g fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="16" cy="16" r="16" stroke="#6BAB3E" fill="#fff" />
                                <path fill="#6BAB3E" fill-rule="nonzero" d="M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"/>
                            </g>
                        </svg>
                    </div>
            
                    <div class="calculator-result__row">
                        <div class="calculator-result__row__icon-wrapper">
                            <svg class="icon--forest" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 42" fill="none" stroke="#6BAB3E">
                            <g fill-rule="evenodd" stroke-linecap="round" stroke-width="2">
                                <path d="M30.356 15.564C30.356 7.52 23.784 1 15.677 1 7.572 1 1 7.52 1 15.564c0 8.043 6.57 14.563 14.678 14.563 8.106 0 14.678-6.52 14.678-14.563zM16.207 12.375v28.583M9.157 15.564l7.05 6.995M16.207 17.49l3.857-3.828M16.226 25.262l5.972-5.926"/>
                            </g>
                        </svg>
                        </div>
                        
                        <div class="calculator-result__row__label"><span v-html="translations.lblForest"></span>:</div>
                        <div class="calculator-result__row__value">{{ calculateForestTime }}</div>
                
                        <svg v-tooltip.left="translations.tooltipForests"
                             class="calculator-result__info no-print"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
                            <g fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="16" cy="16" r="16" stroke="#6BAB3E" fill="#fff" />
                                <path fill="#6BAB3E" fill-rule="nonzero" d="M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"/>
                            </g>
                        </svg>
                    </div>
            
                    <div class="calculator-result__row">
                        <div class="calculator-result__row__icon-wrapper">
                            <svg class="icon--co2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 38" fill="none">
                            <g fill-rule="evenodd">
                                <path stroke="#6BAB3E" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M23.233 25.75c0 5.799-4.805 10.5-10.733 10.5-5.928 0-10.733-4.701-10.733-10.5s4.805-10.5 10.733-10.5c5.928 0 10.733 4.701 10.733 10.5z"/>
                                <path stroke="#6BAB3E" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.833 18.415V9.25c0-4.14 3.435-7.5 7.667-7.5 2.116 0 4.033.84 5.428 2.19 1.38 1.365 2.239 3.24 2.239 5.31v9.165M12.5 25.75v3"/>
                                <path fill="#6BAB3E" d="M14.8 24.25c0 1.244-1.03 2.25-2.3 2.25-1.27 0-2.3-1.006-2.3-2.25S11.23 22 12.5 22c1.27 0 2.3 1.006 2.3 2.25"/>
                            </g>
                        </svg>
                        </div>
                        
                        <div class="calculator-result__row__label"><span v-html="translations.lblCO2"></span>:</div>
                        <div class="calculator-result__row__value">{{ totalCO2.toLocaleString() }}kg CO<sub>2</sub></div>
                
                        <svg v-tooltip.left="translations.tooltipCO2"
                            class="calculator-result__info no-print"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
                            <g fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="16" cy="16" r="16" stroke="#6BAB3E" fill="#fff" />
                                <path fill="#6BAB3E" fill-rule="nonzero" d="M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"/>
                            </g>
                        </svg>
                    </div>
                    
                    <p class="chart-title">{{ translations.titleChart }}</p>
                    
                    <apexchart class="chart" type="bar" :options="options" :series="series"></apexchart>
                    
                    <p class="chart-subtext">{{ translations['dit compenseert'] }}:</p>
            
                    <div class="calculator-result__row">
                        <div class="calculator-result__row__icon-wrapper">
                            <svg class="icon--car" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 23" fill="none" stroke="#6BAB3E">
                            <g fill-rule="evenodd" stroke-linejoin="round" stroke-width="2">
                                <path stroke-linecap="round" d="M25 17.1H14.5M4 17.1h-.69c-.593 0-1.13-.356-1.371-.91L1 14.032l.555-5.811 3.516-5.745c.272-.442.744-.71 1.254-.71H21.46c.39 0 .78.168 1.065.444L28 7.9l4.83.828c1.515.169 2.67 1.487 2.67 3.051v3.788c0 .843-.675 1.533-1.5 1.533"/>
                                <path stroke-linecap="round" d="M13 17.867c0 2.117-1.678 3.833-3.75 3.833S5.5 19.984 5.5 17.867c0-2.118 1.678-3.834 3.75-3.834S13 15.75 13 17.867zM32.5 17.867c0 2.117-1.678 3.833-3.75 3.833S25 19.984 25 17.867c0-2.118 1.678-3.834 3.75-3.834s3.75 1.716 3.75 3.834zM2.5 7.9H28"/>
                                <path d="M14.5 1v6.133"/>
                            </g>
                        </svg>
                        </div>
                        
                        <div class="calculator-result__row__label full-width"><span v-html="translations.lblCarPart1"></span> {{ totalKm.toLocaleString() }} <span v-html="translations.lblCarPart2"></span></div>
                
                        <svg v-tooltip.left="translations.tooltipCar"
                             class="calculator-result__info no-print"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
                            <g fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="16" cy="16" r="16" stroke="#6BAB3E" fill="#fff" />
                                <path fill="#6BAB3E" fill-rule="nonzero" d="M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"/>
                            </g>
                        </svg>
                    </div>
            
                    <div class="calculator-result__row last">
                        <div class="calculator-result__row__icon-wrapper">
                            <svg class="icon--electricity" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 33" fill="none" stroke="#6BAB3E">
                            <g fill-rule="evenodd" stroke-linecap="round" stroke-width="2">
                                <path stroke-linejoin="round" d="M7.133 21.548c0 1.656-.16 4.527 1.534 4.527H14.8c1.129 0 1.64-1.358 1.534-4.075 0-.844 6.085-4.25 6.085-10.5 0-4.882-4.496-9.89-9.44-10.432C6.484.358 1 5.295 1 11.501c0 3.796 2.044 7.145 6.133 10.047zM11.707 17.75l.126 8.325M16.333 13.25l-4.6 4.5M7.133 13.25l4.6 4.5"/>
                                <path d="M7.783 28.75h8.1M8.858 31.873h6.25"/>
                            </g>
                        </svg>
                        </div>
                        
                        <div class="calculator-result__row__label full-width"><span v-html="translations.lblElekPart1"></span> {{ totalEnergyConsumption.toLocaleString() }} {{ totalEnergyConsumptionString }} <span v-html="translations.lblElekPart2"></span>.</div>
                
                        <svg v-tooltip.left="translations.tooltipFamily"
                             class="calculator-result__info no-print"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
                            <g fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="16" cy="16" r="16" stroke="#6BAB3E" fill="#fff" />
                                <path fill="#6BAB3E" fill-rule="nonzero" d="M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        <!--</transition>-->
    
        <transition name="fade">
            <div class="row-ic no-print" v-show="showResults">
                <div class="buttons">
                    <button @click="createPdf()" class="btn btn--filled btn--with-loader">
                        <img src="/assets/img/loader.gif" width="25" height="25" class="mr-3" :alt="translations.loading + '...'" v-show="creatingPdf" v-cloak />
                        {{ translations.btnPdf }}</button>
                    <button @click="showResults = false" class="btn bg-white">{{ translations.btnNew }}</button>
                </div>
                
                <div v-html="translations.resultFootnote"></div>
                
            </div>
        </transition>
        
        <div class="row-ic only-print" v-html="translations.pdfFootnote"></div>
        
        <div class="offScreenPrintArea-wrapper"><div id="offScreenPrintArea"></div></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VeeValidate from 'vee-validate';
    import VueApexCharts from 'vue-apexcharts';
    import jsPDF from "jspdf";
    import domtoimage from 'dom-to-image';
    //import html2canvas from "html2canvas"
    import VTooltip from 'v-tooltip';
    
    const colors = require('../../tailwind.colors');
    
    Vue.use(VeeValidate, {
        classes: true,
        mode: 'eager'
    });
    
    Vue.use(VTooltip, {
        defaultDelay: 100,
        defaultTrigger: 'hover click'
    });
    
    export default {
        components: {
            'apexchart': VueApexCharts
        },
        
        mounted() {},
        
        props: {
            woodTypes: Array,
            translations: Object
        },
        
        data() {
            return {
                // Inputs
                calculatorData: [
                    {
                        volume: null,
                        wood: {}
                    }
                ],
                projectRef: '',
                showResults: false,
                creatingPdf: false,
                pdfDoc: null,
                
                // Outputs
                totalCO2: 0,
                totalVolume: 0,
                totalKm: 0,
                totalEnergyConsumption: 0,
                
                // Constants
                FOREST_CO2_STORAGE: 13500, // kg CO2
                AVERAGE_CAR_CO2_EMISSION: 0.15, // kg/km
                ENERGY_CONSUMPTION: 900, // kg/family
                
                // Other
                firstWoodTypeNotSelected: true,
                
                // Chart
                options: {
                    chart: {
                        height: 300,
                        id: 'vuechart-netto-emissions',
                        toolbar: { show: false }
                    },
                    legend: { show: false },
                    tooltip: { enabled: false },
                    yaxis: {
                        show: false,
                        labels: {
                            offsetX: 20,
                            rotate: -90
                        },
                    },
                    xaxis: {
                        categories: [this.translations['hout'], this.translations['beton'], this.translations['pvc'], this.translations['staal'], this.translations['alluminium']],
                        labels: {
                            show: true,
                            style: {
                                fontSize: '16px',
                                cssClass: 'apexcharts-xaxis-label'
                            },
                            offsetY: 10
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: { show: false },
                    },
                    colors: Object.values(colors.chart),
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            distributed: true,
                            dataLabels: { position: 'top' }
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -40,
                        formatter: function(val, index) {
                            // First value is always negative and gets its '-' from the number
                            let posOrNeg = index.dataPointIndex > 0 ? '+ ' : '';
                            return posOrNeg + val.toLocaleString()
                        },
                        style: {
                            fontSize: '18px',
                            colors: colors.black
                            //colors: Object.values(colors.chart) // Doesn't support distributed colors yet
                        },
                    },
                    grid: {
                        show: true,
                        borderColor: colors.grey["200"]
                    },
                    states: {
                        hover: {
                            filter: { type: 'none' }
                        }
                    },
                    responsive: [{
                        breakpoint: 767,
                        options: {
                            chart: {
                                height: 500,
                            },
                            xaxis: {
                                labels: {
                                    show: false
                                }
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    dataLabels: { position: 'bottom' },
                                    barHeight: '100%'
                                }
                            },
                            dataLabels: {
                                offsetY: 0,
                                textAnchor: 'start'
                            }
                        },
                    }]
                },
                series: [{
                    data: [0, 0, 0, 0, 0]
                }]
            }
        },
        
        methods: {
            createPdf() {
                let vm = this;
    
                // Show loader icon
                this.creatingPdf = true;
                
                // Create a new jsPDF doc
                this.pdfDoc = new jsPDF('p', 'px', 'a4');
                
                // Get the element that contains the content to print
                let node = this.$refs.calculatorprint;
                
                // Duplicate this node
                let nodeDuplicate = node.cloneNode(true);
                
                // Apply some custom print styles
                nodeDuplicate.classList.add('print-wrapper');
                
                // Append this node to the DOM off-screen (else domtoimage cannot extract base64data from it
                document.getElementById('offScreenPrintArea').appendChild(nodeDuplicate);
                
                // Select all nodes that don't need to be printed and remove them
                let noPrintNodes = nodeDuplicate.querySelectorAll('.no-print');
                noPrintNodes.forEach(el => el.remove());
                
                // Select all nodes that are print only and show them
                let onlyPrintNodes = nodeDuplicate.querySelectorAll('.only-print');
                onlyPrintNodes.forEach(el => el.classList.remove('only-print'));
    
                /*
                html2canvas(nodeDuplicate, { allowTaint: true })
                    .then(canvas => {
                        // Convert canvas to base64 image
                        let imgData = canvas.toDataURL('image/png', 1.0);
                        return imgData;
                    }).then(imgData => {
                        // Get the image height and width
                        let imgProps = vm.pdfDoc.getImageProperties(imgData);
    
                        // Set a scale for the PDF container (trial and error)
                        let scale = 0.36;
                    
                        // Add the image to the PDF
                        this.pdfDoc.addImage(imgData, 'JPEG',
                            10, 10,
                            imgProps.width * scale, imgProps.height * scale,
                            null,
                            "MEDIUM",
                        );
            
                        // todo: Timeout needed?
                        setTimeout(() => {
                            resolve();
                        }, 150);
                    }).then(() => {
                        // Create the PDF
                        vm.pdfDoc.save('certificate.pdf');
        
                        // Clean up the DOM
                        document.getElementById('offScreenPrintArea').removeChild(nodeDuplicate);
        
                        // Hide loader icon
                        this.creatingPdf = false;
                    })
                    .catch(error => {
                        console.error('oops, something went wrong!', error);
                    });
                    */
                
                // Convert the node to base64data
                domtoimage.toPng(nodeDuplicate, {})
                    .then(dataUrl => {
                        // Add the base64data to an image
                        let img = new Image();
                        img.src = dataUrl;
    
                        // Get the image height and width
                        let imgProps = vm.pdfDoc.getImageProperties(dataUrl);
                        
                        // Set a scale for the PDF container (trial and error)
                        let scale = 0.36;
                        
                        // Add the image to the PDF
                        vm.pdfDoc.addImage(img, 'JPEG', 25, 20, imgProps.width * scale, imgProps.height * scale, null, 'MEDIUM');
                    })
                    .then(() => {
                        // Create the PDF
                        vm.pdfDoc.save('certificate.pdf');
                        
                        // Clean up the DOM
                        document.getElementById('offScreenPrintArea').removeChild(nodeDuplicate);
                        
                        // Hide loader icon
                        this.creatingPdf = false;
                    })
                    .catch(error => {
                        console.error('oops, something went wrong!', error);
                    });
            },
            
            updateChart() {
                let chartWood = -(this.totalCO2);
                let chartConcrete = this.totalCO2 / 2;
                let chartPvc = this.totalCO2 * 6.25;
                let chartSteel = this.totalCO2 * 20.62;
                let chartAluminum = this.totalCO2 * 33.75;
                
                this.series = [{
                    data: [chartWood, chartConcrete, chartPvc, chartSteel, chartAluminum]
                }]
            },
            
            addNewRow() {
                this.calculatorData.push({
                    volume: null,
                    wood: {}
                })
            },
            
            deleteRow(index) {
                if (this.calculatorData[index] != null) {
                    this.calculatorData.splice(index, 1);
                }
            },
            
            /*
            clearEmptyRows() {
                this.calculatorData.forEach((woodType, index) => {
                    if(woodType.volume === null || woodType.volume === '' || woodType.volume === 0){
                        this.deleteRow(index);
                    }
                });
            },
            */
            
            calculateResult() {
                this.$validator.validate().then(valid => {
                    if (valid) {
                        let totalCO2 = 0;
                        let totalVolume = 0;
                        let totalKm = 0;
                        let totalEnergyConsumption = 0;
                        
                        this.calculatorData.forEach(woodType => {
                            totalCO2 = totalCO2 + (((woodType.volume * woodType.wood.woodDensity) / 2) * 3.67);
                            totalVolume = totalVolume + parseFloat(woodType.volume, 10);
                            totalKm = totalKm + totalCO2 / this.AVERAGE_CAR_CO2_EMISSION;
                            totalEnergyConsumption = totalEnergyConsumption + totalCO2 / this.ENERGY_CONSUMPTION;
                        });
                        
                        this.totalCO2 = Math.ceil(totalCO2);
                        this.totalVolume = Math.round(totalVolume * 100) / 100; // Not accurate!
                        this.totalKm = Math.ceil(totalKm);
                        this.totalEnergyConsumption = Math.ceil(totalEnergyConsumption);
                        
                        this.updateChart();
                        
                        this.showResults = true;
                    } else {
                        // Validation errors are handled inside the form
                    }
                });
            }
        },
        
        computed: {
            calculateForestTime() {
                let timeInSeconds = this.totalCO2 / this.FOREST_CO2_STORAGE;
                
                if (timeInSeconds < 1) {
                    return this.translations.str1S;
                } else if (timeInSeconds < 60) {
                    return Math.ceil(timeInSeconds) + ' ' + this.translations.strS;
                } else if (timeInSeconds < 3600) {
                    let timeInMinutes = Math.floor(timeInSeconds / 60);
                    let timeInMinutesRestSeconds = timeInSeconds % 60;
                    return timeInMinutes + ' ' + this.translations.strMAnd + ' ' + Math.ceil(timeInMinutesRestSeconds) + ' ' + this.translations.strS;
                } else {
                    let timeInHours = Math.floor(timeInSeconds / 3600);
                    let timeInHoursRestSeconds = timeInSeconds % 3600;
                    let timeInHoursRestMinutes = timeInHoursRestSeconds % 60;
                    return timeInHours + ' ' + this.translations.strHAnd + ' ' + Math.ceil(timeInHoursRestMinutes) + ' ' + this.translations.strM;
                }
            },
    
            totalEnergyConsumptionString() {
                return this.totalEnergyConsumption > 1 ? this.translations.huishoudens : this.translations.huishouden;
            }
        }
    }
</script>
