let colors = {
    white: '#ffffff',
    black: '#000001',
    blue: '#000937',
    green: {
        '500': '#6bab3e',
        '600': '#588a34'
    },
    grey: {
        '100': '#f9f9fa',
        '200': '#f0f0f5',
        '300': '#dbdbe0',
        '500': '#9d9d9f'
    },
    chart: {
        '100': '#6bab3e',
        '200': '#d98000',
        '300': '#cd4400',
        '400': '#d90201',
        '500': '#ae0001',
    }
};

module.exports = colors;