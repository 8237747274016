var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "calculatorprint", staticClass: "calculator-wrapper row-ic" },
    [
      _c("div", { staticClass: "icon-calc-large no-print" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
              viewBox: "0 0 36 48"
            }
          },
          [
            _c("defs", [
              _c("path", { attrs: { id: "a", d: "M0 48h36V0H0z" } })
            ]),
            _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
              _c("path", {
                attrs: {
                  stroke: "#FFF",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d:
                    "M33 47H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2v42a2 2 0 0 1-2 2z"
                }
              }),
              _c("mask", { attrs: { id: "b", fill: "#fff" } }, [
                _c("use", { attrs: { "xlink:href": "#a" } })
              ]),
              _c("path", {
                attrs: { fill: "#FFF", d: "M7 15h22V7H7z", mask: "url(#b)" }
              }),
              _c("path", {
                attrs: {
                  stroke: "#FFF",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M11 21v8M15 25H7M23 39h6M27 25L9 39",
                  mask: "url(#b)"
                }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex justify-between items-end w-full mb-16 only-print"
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 271 142",
                fill: "#6bab3e",
                width: "200"
              }
            },
            [
              _c("g", { attrs: { "fill-rule": "evenodd" } }, [
                _c("path", {
                  attrs: {
                    d:
                      "M270.883 109.884c0-6.774-1.638-13.467-5.573-19.002-6.884-9.583-15.818-11.401-26.8-11.401h-20.08v61.138h21.228c19.833 0 31.225-10.657 31.225-30.735m-15.49.25c0 9.914-4.262 16.771-14.834 16.771h-6.639v-33.71h6.72c10.328 0 14.753 7.189 14.753 16.939M211.255 110.05c0-17.894-14.342-31.52-32.045-31.52-10.327 0-19.424 4.269-25.652 12.478-1.558 2.052-3.033 4.43-3.934 6.811-1.64 4.187-2.377 8.455-2.377 12.97 0 17.484 14.752 30.781 31.881 30.781 17.785 0 32.127-13.544 32.127-31.52m-15.49.083c0 9.275-6.556 17.811-16.308 17.811-10.082 0-16.72-8.371-16.72-18.057 0-9.524 6.638-17.731 16.555-17.731s16.473 8.536 16.473 17.977M62.041 79.481l-8.278 39.906H53.6l-8.77-39.906H32.947l-8.934 39.741h-.164l-8.114-39.741H0l15.653 61.139h14.016l9.097-39.575h.163l9.098 39.575h13.604l16.065-61.139zM146.112 65.541c0-10.613-8.589-19.215-19.185-19.215-10.596 0-19.186 8.602-19.186 19.215 0 1.86.277 3.65.77 5.35a36.57 36.57 0 0 1 2.677-.109c10.11 0 19.116 4.448 24.99 11.591 5.92-3.271 9.934-9.58 9.934-16.832m-37.601 5.35c-9.216.68-17.292 4.877-22.975 12.367-1.557 2.053-3.032 4.433-3.934 6.814-1.64 4.185-2.377 8.454-2.377 12.969 0 17.483 14.753 30.781 31.88 30.781 17.786 0 32.128-13.544 32.128-31.52 0-7.676-2.644-14.561-7.056-19.929a19.069 19.069 0 0 1-9.25 2.383c-8.74 0-16.101-5.858-18.416-13.865m2.76 13.516c9.916 0 16.473 8.537 16.473 17.978 0 9.275-6.557 17.81-16.31 17.81-10.08 0-16.72-8.371-16.72-18.056 0-9.522 6.64-17.732 16.557-17.732M100.764 51.532c7.225 0 13.081-5.866 13.081-13.102 0-7.235-5.856-13.1-13.08-13.1-7.225 0-13.082 5.865-13.082 13.1 0 7.236 5.857 13.102 13.081 13.102M117.624 19.216c5.298 0 9.594-4.303 9.594-9.609S122.922 0 117.624 0c-5.297 0-9.592 4.3-9.592 9.607 0 5.306 4.295 9.609 9.592 9.609"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d:
                      "M137.913 140.62v-2.94h-4.632c2.049-1.793 4.54-4.067 4.54-7.024 0-.537-.129-1.073-.35-1.572-.794-1.664-2.53-2.405-4.301-2.405-1.661 0-3.286.668-4.21 2.09-.645.999-.775 1.978-.811 3.144h3.322c.056-1.017.314-2.163 1.57-2.163.793 0 1.292.554 1.292 1.33 0 1.22-1.09 2.626-1.883 3.495-1.237 1.368-2.64 2.33-4.171 3.327v2.718h9.634z"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("h1", [_vm._v(_vm._s(_vm.translations.certificaat))])
        ]
      ),
      _vm._v(" "),
      !_vm.showResults
        ? _c(
            "div",
            {
              key: "calculatorContent",
              staticClass: "row-ic calculator-content no-print"
            },
            [
              _c("h2", { staticClass: "mb-8" }, [
                _vm._v(_vm._s(_vm.translations.titleCalculator) + " m"),
                _c("sup", [_vm._v("3")])
              ]),
              _vm._v(" "),
              _c(
                "transition-group",
                { attrs: { name: "fade", tag: "div" } },
                _vm._l(_vm.calculatorData, function(row, index) {
                  return _c(
                    "div",
                    {
                      key: "row" + index,
                      staticClass: "calculator__input-row mb-6"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "select-styling mr-8",
                          class: {
                            invalid: _vm.errors.first("woodType" + index)
                          }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.calculatorData[index].wood,
                                  expression: "calculatorData[index].wood"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: { name: "woodType" + index },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.calculatorData[index],
                                      "wood",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    _vm.firstWoodTypeNotSelected = false
                                  }
                                ]
                              }
                            },
                            _vm._l(_vm.woodTypes, function(type, i) {
                              return _c(
                                "option",
                                { domProps: { value: type } },
                                [_vm._v(_vm._s(type.woodType))]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "field-sticky-label mr-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: row.volume,
                              expression: "row.volume"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            type: "number",
                            name: "woodVolume",
                            placeholder: _vm.translations.volume,
                            min: "0.1",
                            step: "0.1"
                          },
                          domProps: { value: row.volume },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(row, "volume", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "sticky-label" }, [
                          _vm._v("m³")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "calculator__input-row__buttons" },
                        [
                          _vm.calculatorData.length !== 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn--delete mr-8",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteRow(index)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 21 25",
                                        fill: "none",
                                        stroke: "#6BAB3E"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "2"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M1 4h19M14.5 4h-8v-.69A2.31 2.31 0 0 1 8.81 1h3.38a2.31 2.31 0 0 1 2.31 2.31V4zM10.5 8v12M6.5 8v12M14.5 8v12M14.646 24H6.354a3 3 0 0 1-2.995-2.851L2.5 4h16l-.858 17.149A3 3 0 0 1 14.647 24z"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index === _vm.calculatorData.length - 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn--add-row",
                                  on: {
                                    click: function($event) {
                                      return _vm.addNewRow()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "btn__icon icon-plus",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 31.4 31.4",
                                        fill: "#fff"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M15.7 31.4c-1.1 0-2-.9-2-2V17.7H2c-1.1 0-2-.9-2-2s.9-2 2-2h11.7V2c0-1.1.9-2 2-2s2 .9 2 2v11.7h11.7c1.1 0 2 .9 2 2s-.9 2-2 2H17.7v11.7c0 1.1-.9 2-2 2z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.translations.houtsoort) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("label", { staticClass: "mt-8 mb-6" }, [
                _vm._v(_vm._s(_vm.translations.lblRefProject))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.projectRef,
                    expression: "projectRef"
                  }
                ],
                staticClass: "full-width",
                attrs: {
                  type: "text",
                  name: "refProject",
                  placeholder: _vm.translations.placeRefProject
                },
                domProps: { value: _vm.projectRef },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.projectRef = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "function-bar mt-12" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn--filled self-start",
                      attrs: {
                        disabled:
                          _vm.firstWoodTypeNotSelected ||
                          _vm.calculatorData[0].volume === "" ||
                          _vm.calculatorData[0].volume === null ||
                          _vm.errors.all().length > 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.calculateResult()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.translations.btnCalculate))]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.all().length > 0,
                            expression: "errors.all().length > 0"
                          }
                        ],
                        staticClass: "error"
                      },
                      [_vm._v(_vm._s(_vm.translations.formGeneralError))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              key: "calculatorResult",
              staticClass: "row-ic calculator-result"
            },
            [
              _c("h2", { staticClass: "mb-8" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.translations.titleCO2) }
                }),
                _vm._v(" " + _vm._s(_vm.totalCO2.toLocaleString()) + "kg")
              ]),
              _vm._v(" "),
              this.projectRef
                ? _c("p", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.translations["uw referentie"]) + ": "),
                    _c("span", { staticClass: "font-medium" }, [
                      _vm._v(_vm._s(this.projectRef))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "calculator-result__summary" },
                [
                  _c("div", { staticClass: "calculator-result__row" }, [
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__icon-wrapper" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon--volume",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 34 36",
                              fill: "none",
                              stroke: "#6BAB3E"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M32.385 22a1.44 1.44 0 0 1 0 2.36L17.892 34.717a1.544 1.544 0 0 1-1.784 0L1.615 24.36a1.44 1.44 0 0 1 0-2.36"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M1.615 17a1.44 1.44 0 0 0 0 2.36l1.422 1.018 13.071 9.339c.53.377 1.254.377 1.784 0l13.07-9.339 1.423-1.018a1.44 1.44 0 0 0 0-2.36"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M32.386 11.802L17.892 1.289a1.52 1.52 0 0 0-1.784 0L1.614 11.802a1.476 1.476 0 0 0 0 2.396l1.424 1.033 3.071 2.231 10 7.25a1.526 1.526 0 0 0 1.783 0l10-7.25 3.072-2.23 1.422-1.034a1.476 1.476 0 0 0 0-2.396z"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__label" },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblVolume)
                          }
                        }),
                        _vm._v(":")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__value" },
                      [
                        _vm._v(_vm._s(_vm.totalVolume.toLocaleString()) + " m"),
                        _c("sup", [_vm._v("3")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.left",
                            value: _vm.translations.tooltipVolume,
                            expression: "translations.tooltipVolume",
                            modifiers: { left: true }
                          }
                        ],
                        staticClass: "calculator-result__info no-print",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 34 34",
                          fill: "none"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "fill-rule": "evenodd",
                              transform: "translate(1 1)"
                            }
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "16",
                                cy: "16",
                                r: "16",
                                stroke: "#6BAB3E",
                                fill: "#fff"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "#6BAB3E",
                                "fill-rule": "nonzero",
                                d:
                                  "M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "calculator-result__row" }, [
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__icon-wrapper" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon--forest",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 32 42",
                              fill: "none",
                              stroke: "#6BAB3E"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "stroke-linecap": "round",
                                  "stroke-width": "2"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M30.356 15.564C30.356 7.52 23.784 1 15.677 1 7.572 1 1 7.52 1 15.564c0 8.043 6.57 14.563 14.678 14.563 8.106 0 14.678-6.52 14.678-14.563zM16.207 12.375v28.583M9.157 15.564l7.05 6.995M16.207 17.49l3.857-3.828M16.226 25.262l5.972-5.926"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__label" },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblForest)
                          }
                        }),
                        _vm._v(":")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__value" },
                      [_vm._v(_vm._s(_vm.calculateForestTime))]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.left",
                            value: _vm.translations.tooltipForests,
                            expression: "translations.tooltipForests",
                            modifiers: { left: true }
                          }
                        ],
                        staticClass: "calculator-result__info no-print",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 34 34",
                          fill: "none"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "fill-rule": "evenodd",
                              transform: "translate(1 1)"
                            }
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "16",
                                cy: "16",
                                r: "16",
                                stroke: "#6BAB3E",
                                fill: "#fff"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "#6BAB3E",
                                "fill-rule": "nonzero",
                                d:
                                  "M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "calculator-result__row" }, [
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__icon-wrapper" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon--co2",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 25 38",
                              fill: "none"
                            }
                          },
                          [
                            _c("g", { attrs: { "fill-rule": "evenodd" } }, [
                              _c("path", {
                                attrs: {
                                  stroke: "#6BAB3E",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M23.233 25.75c0 5.799-4.805 10.5-10.733 10.5-5.928 0-10.733-4.701-10.733-10.5s4.805-10.5 10.733-10.5c5.928 0 10.733 4.701 10.733 10.5z"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  stroke: "#6BAB3E",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M4.833 18.415V9.25c0-4.14 3.435-7.5 7.667-7.5 2.116 0 4.033.84 5.428 2.19 1.38 1.365 2.239 3.24 2.239 5.31v9.165M12.5 25.75v3"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  fill: "#6BAB3E",
                                  d:
                                    "M14.8 24.25c0 1.244-1.03 2.25-2.3 2.25-1.27 0-2.3-1.006-2.3-2.25S11.23 22 12.5 22c1.27 0 2.3 1.006 2.3 2.25"
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__label" },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblCO2)
                          }
                        }),
                        _vm._v(":")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__value" },
                      [
                        _vm._v(_vm._s(_vm.totalCO2.toLocaleString()) + "kg CO"),
                        _c("sub", [_vm._v("2")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.left",
                            value: _vm.translations.tooltipCO2,
                            expression: "translations.tooltipCO2",
                            modifiers: { left: true }
                          }
                        ],
                        staticClass: "calculator-result__info no-print",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 34 34",
                          fill: "none"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "fill-rule": "evenodd",
                              transform: "translate(1 1)"
                            }
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "16",
                                cy: "16",
                                r: "16",
                                stroke: "#6BAB3E",
                                fill: "#fff"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "#6BAB3E",
                                "fill-rule": "nonzero",
                                d:
                                  "M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "chart-title" }, [
                    _vm._v(_vm._s(_vm.translations.titleChart))
                  ]),
                  _vm._v(" "),
                  _c("apexchart", {
                    staticClass: "chart",
                    attrs: {
                      type: "bar",
                      options: _vm.options,
                      series: _vm.series
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "chart-subtext" }, [
                    _vm._v(_vm._s(_vm.translations["dit compenseert"]) + ":")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "calculator-result__row" }, [
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__icon-wrapper" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon--car",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 37 23",
                              fill: "none",
                              stroke: "#6BAB3E"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    d:
                                      "M25 17.1H14.5M4 17.1h-.69c-.593 0-1.13-.356-1.371-.91L1 14.032l.555-5.811 3.516-5.745c.272-.442.744-.71 1.254-.71H21.46c.39 0 .78.168 1.065.444L28 7.9l4.83.828c1.515.169 2.67 1.487 2.67 3.051v3.788c0 .843-.675 1.533-1.5 1.533"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    d:
                                      "M13 17.867c0 2.117-1.678 3.833-3.75 3.833S5.5 19.984 5.5 17.867c0-2.118 1.678-3.834 3.75-3.834S13 15.75 13 17.867zM32.5 17.867c0 2.117-1.678 3.833-3.75 3.833S25 19.984 25 17.867c0-2.118 1.678-3.834 3.75-3.834s3.75 1.716 3.75 3.834zM2.5 7.9H28"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", { attrs: { d: "M14.5 1v6.133" } })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "calculator-result__row__label full-width"
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblCarPart1)
                          }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.totalKm.toLocaleString()) + " "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblCarPart2)
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.left",
                            value: _vm.translations.tooltipCar,
                            expression: "translations.tooltipCar",
                            modifiers: { left: true }
                          }
                        ],
                        staticClass: "calculator-result__info no-print",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 34 34",
                          fill: "none"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "fill-rule": "evenodd",
                              transform: "translate(1 1)"
                            }
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "16",
                                cy: "16",
                                r: "16",
                                stroke: "#6BAB3E",
                                fill: "#fff"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "#6BAB3E",
                                "fill-rule": "nonzero",
                                d:
                                  "M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "calculator-result__row last" }, [
                    _c(
                      "div",
                      { staticClass: "calculator-result__row__icon-wrapper" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon--electricity",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 33",
                              fill: "none",
                              stroke: "#6BAB3E"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "stroke-linecap": "round",
                                  "stroke-width": "2"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linejoin": "round",
                                    d:
                                      "M7.133 21.548c0 1.656-.16 4.527 1.534 4.527H14.8c1.129 0 1.64-1.358 1.534-4.075 0-.844 6.085-4.25 6.085-10.5 0-4.882-4.496-9.89-9.44-10.432C6.484.358 1 5.295 1 11.501c0 3.796 2.044 7.145 6.133 10.047zM11.707 17.75l.126 8.325M16.333 13.25l-4.6 4.5M7.133 13.25l4.6 4.5"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M7.783 28.75h8.1M8.858 31.873h6.25"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "calculator-result__row__label full-width"
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblElekPart1)
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.totalEnergyConsumption.toLocaleString()
                            ) +
                            " " +
                            _vm._s(_vm.totalEnergyConsumptionString) +
                            " "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.translations.lblElekPart2)
                          }
                        }),
                        _vm._v(".")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.left",
                            value: _vm.translations.tooltipFamily,
                            expression: "translations.tooltipFamily",
                            modifiers: { left: true }
                          }
                        ],
                        staticClass: "calculator-result__info no-print",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 34 34",
                          fill: "none"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "fill-rule": "evenodd",
                              transform: "translate(1 1)"
                            }
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "16",
                                cy: "16",
                                r: "16",
                                stroke: "#6BAB3E",
                                fill: "#fff"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "#6BAB3E",
                                "fill-rule": "nonzero",
                                d:
                                  "M16.41 19.72h-1.26s-.09-.396-.09-.81c0-2.88 3.51-3.06 3.51-5.49 0-1.17-.99-2.16-2.61-2.16-2.34 0-2.79 1.98-2.79 1.98L12 12.88S12.72 10 15.96 10c2.34 0 3.96 1.62 3.96 3.42 0 3.42-3.51 2.97-3.51 5.94v.36zm-1.53 2.34c0-.54.36-.9.9-.9s.9.36.9.9-.36.9-.9.9-.9-.36-.9-.9z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ]
          ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showResults,
                expression: "showResults"
              }
            ],
            staticClass: "row-ic no-print"
          },
          [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn--filled btn--with-loader",
                  on: {
                    click: function($event) {
                      return _vm.createPdf()
                    }
                  }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.creatingPdf,
                        expression: "creatingPdf"
                      }
                    ],
                    staticClass: "mr-3",
                    attrs: {
                      src: "/assets/img/loader.gif",
                      width: "25",
                      height: "25",
                      alt: _vm.translations.loading + "..."
                    }
                  }),
                  _vm._v(
                    "\n                    " + _vm._s(_vm.translations.btnPdf)
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn bg-white",
                  on: {
                    click: function($event) {
                      _vm.showResults = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.translations.btnNew))]
              )
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.translations.resultFootnote) }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "row-ic only-print",
        domProps: { innerHTML: _vm._s(_vm.translations.pdfFootnote) }
      }),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "offScreenPrintArea-wrapper" }, [
      _c("div", { attrs: { id: "offScreenPrintArea" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }